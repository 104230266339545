var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_mounted
    ? _c("div", { attrs: { align: "center" } }, [
        _c("br"),
        _vm._m(0),
        _c("div", { staticClass: "row justify-center q-px-md" }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-4 q-mr-sm" },
            [
              _c("div", { staticClass: "text-h5", attrs: { align: "left" } }, [
                _vm._v("Dati anagrafici"),
              ]),
              _c("br"),
              _c("q-select", {
                staticClass: "q-mb-sm",
                attrs: {
                  dense: "",
                  outlined: "",
                  options: _vm.options_tipo_persona,
                  label: "Tipo di persona",
                },
                model: {
                  value: _vm.dati_cliente_modificati.tipo_persona,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_cliente_modificati, "tipo_persona", $$v)
                  },
                  expression: "dati_cliente_modificati.tipo_persona",
                },
              }),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: {
                  dense: "",
                  outlined: "",
                  label: "Cognome - Ragione sociale",
                },
                model: {
                  value: _vm.dati_cliente_modificati.cognome,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_cliente_modificati, "cognome", $$v)
                  },
                  expression: "dati_cliente_modificati.cognome",
                },
              }),
              _vm.dati_cliente_modificati.tipo_persona === "persona_fisica"
                ? [
                    _c("q-input", {
                      staticClass: "q-mb-sm",
                      attrs: { dense: "", outlined: "", label: "Nome" },
                      model: {
                        value: _vm.dati_cliente_modificati.nome,
                        callback: function ($$v) {
                          _vm.$set(_vm.dati_cliente_modificati, "nome", $$v)
                        },
                        expression: "dati_cliente_modificati.nome",
                      },
                    }),
                    _c("q-select", {
                      staticClass: "q-mb-sm",
                      attrs: {
                        dense: "",
                        outlined: "",
                        options: _vm.options_sesso,
                        label: "Sesso",
                      },
                      model: {
                        value: _vm.dati_cliente_modificati.sesso,
                        callback: function ($$v) {
                          _vm.$set(_vm.dati_cliente_modificati, "sesso", $$v)
                        },
                        expression: "dati_cliente_modificati.sesso",
                      },
                    }),
                    _c("q-input", {
                      staticClass: "q-mb-sm",
                      attrs: {
                        dense: "",
                        outlined: "",
                        label: "Codice Fiscale",
                      },
                      model: {
                        value: _vm.dati_cliente_modificati.codice_fiscale,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dati_cliente_modificati,
                            "codice_fiscale",
                            $$v
                          )
                        },
                        expression: "dati_cliente_modificati.codice_fiscale",
                      },
                    }),
                    _c("q-input", {
                      staticClass: "q-mb-sm",
                      attrs: {
                        outlined: "",
                        mask: "##/##/####",
                        dense: "",
                        label: "Data di nascita",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  "q-icon",
                                  {
                                    staticClass: "cursor-pointer",
                                    attrs: { name: "event" },
                                  },
                                  [
                                    _c(
                                      "q-popup-proxy",
                                      {
                                        ref: "qDateProxy",
                                        attrs: {
                                          "transition-show": "scale",
                                          "transition-hide": "scale",
                                        },
                                      },
                                      [
                                        _c(
                                          "q-date",
                                          {
                                            attrs: { mask: "DD/MM/YYYY" },
                                            model: {
                                              value:
                                                _vm.dati_cliente_modificati
                                                  .data_nascita,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dati_cliente_modificati,
                                                  "data_nascita",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dati_cliente_modificati.data_nascita",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row items-center justify-end",
                                              },
                                              [
                                                _c("q-btn", {
                                                  directives: [
                                                    {
                                                      name: "close-popup",
                                                      rawName: "v-close-popup",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: "Close",
                                                    color: "primary",
                                                    flat: "",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1907780042
                      ),
                      model: {
                        value: _vm.dati_cliente_modificati.data_nascita,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dati_cliente_modificati,
                            "data_nascita",
                            $$v
                          )
                        },
                        expression: "dati_cliente_modificati.data_nascita",
                      },
                    }),
                    _c("q-select", {
                      staticClass: "q-mb-sm",
                      attrs: {
                        name: "comune_nascita",
                        "emit-value": "",
                        dense: "",
                        "map-options": "",
                        "virtual-scroll-slice-size": "0",
                        "virtual-scroll-item-size": "0",
                        outlined: "",
                        "use-input": "",
                        "input-debounce": "0",
                        options: _vm.elenco_comuni_nascita,
                      },
                      on: {
                        filter: _vm.onCambiaComuneNascita,
                        input: _vm.cambiaComuneNascita,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "no-option",
                            fn: function () {
                              return [
                                _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      { staticClass: "text-grey" },
                                      [_vm._v(" Nessun risultato ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2139249595
                      ),
                      model: {
                        value: _vm.dati_cliente_modificati.comune_nascita,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dati_cliente_modificati,
                            "comune_nascita",
                            $$v
                          )
                        },
                        expression: "dati_cliente_modificati.comune_nascita",
                      },
                    }),
                    _c("q-select", {
                      staticClass: "q-mb-sm",
                      attrs: {
                        dense: "",
                        outlined: "",
                        options: _vm.options_cap_nascita,
                        label: "Cap di nascita",
                      },
                      model: {
                        value: _vm.dati_cliente_modificati.cap_nascita,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dati_cliente_modificati,
                            "cap_nascita",
                            $$v
                          )
                        },
                        expression: "dati_cliente_modificati.cap_nascita",
                      },
                    }),
                    _c("q-input", {
                      staticClass: "q-mb-sm",
                      attrs: {
                        dense: "",
                        outlined: "",
                        label: "Provincia nascita",
                        disable: "",
                      },
                      model: {
                        value: _vm.dati_cliente_modificati.provincia_nascita,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dati_cliente_modificati,
                            "provincia_nascita",
                            $$v
                          )
                        },
                        expression: "dati_cliente_modificati.provincia_nascita",
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.dati_cliente_modificati.tipo_persona === "professionista"
                ? [
                    _c("q-input", {
                      staticClass: "q-mb-sm",
                      attrs: { dense: "", outlined: "", label: "Nome" },
                      model: {
                        value: _vm.dati_cliente_modificati.nome,
                        callback: function ($$v) {
                          _vm.$set(_vm.dati_cliente_modificati, "nome", $$v)
                        },
                        expression: "dati_cliente_modificati.nome",
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.dati_cliente_modificati.tipo_persona !== "persona_fisica"
                ? [
                    _c("q-input", {
                      staticClass: "q-mb-sm",
                      attrs: { dense: "", outlined: "", label: "Partita IVA" },
                      model: {
                        value: _vm.dati_cliente_modificati.partita_iva,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dati_cliente_modificati,
                            "partita_iva",
                            $$v
                          )
                        },
                        expression: "dati_cliente_modificati.partita_iva",
                      },
                    }),
                    _c("q-input", {
                      staticClass: "q-mb-sm",
                      attrs: {
                        dense: "",
                        outlined: "",
                        label: "Codice Univoco Destinatario",
                      },
                      model: {
                        value:
                          _vm.dati_cliente_modificati
                            .codice_univoco_destinatario,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dati_cliente_modificati,
                            "codice_univoco_destinatario",
                            $$v
                          )
                        },
                        expression:
                          "dati_cliente_modificati.codice_univoco_destinatario",
                      },
                    }),
                  ]
                : _vm._e(),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: { dense: "", outlined: "", label: "Professione" },
                model: {
                  value: _vm.dati_cliente_modificati.professione,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_cliente_modificati, "professione", $$v)
                  },
                  expression: "dati_cliente_modificati.professione",
                },
              }),
              _c("q-select", {
                staticClass: "q-mb-sm",
                attrs: {
                  dense: "",
                  outlined: "",
                  "emit-value": "",
                  "map-options": "",
                  options: _vm.options_si_no,
                  label: "Esente obbligo PEC",
                },
                model: {
                  value: _vm.dati_cliente_modificati.esente_obbligo_pec,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dati_cliente_modificati,
                      "esente_obbligo_pec",
                      $$v
                    )
                  },
                  expression: "dati_cliente_modificati.esente_obbligo_pec",
                },
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-4 q-mr-sm" },
            [
              _c("div", { staticClass: "text-h5", attrs: { align: "left" } }, [
                _vm._v("Indirizzo di residenza / Sede"),
              ]),
              _c("br"),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: { dense: "", outlined: "", label: "Indirizzo" },
                model: {
                  value: _vm.dati_cliente_modificati.indirizzo_residenza,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dati_cliente_modificati,
                      "indirizzo_residenza",
                      $$v
                    )
                  },
                  expression: "dati_cliente_modificati.indirizzo_residenza",
                },
              }),
              _c("q-select", {
                staticClass: "q-mb-sm",
                attrs: {
                  name: "comune_residenza",
                  "emit-value": "",
                  dense: "",
                  "map-options": "",
                  "virtual-scroll-slice-size": "0",
                  "virtual-scroll-item-size": "0",
                  outlined: "",
                  "use-input": "",
                  "input-debounce": "0",
                  options: _vm.elenco_comuni_residenza,
                },
                on: {
                  filter: _vm.onCambiaComuneResidenza,
                  input: _vm.cambiaComuneResidenza,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "no-option",
                      fn: function () {
                        return [
                          _c(
                            "q-item",
                            [
                              _c(
                                "q-item-section",
                                { staticClass: "text-grey" },
                                [_vm._v(" Nessun risultato ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2139249595
                ),
                model: {
                  value: _vm.dati_cliente_modificati.comune_residenza,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dati_cliente_modificati,
                      "comune_residenza",
                      $$v
                    )
                  },
                  expression: "dati_cliente_modificati.comune_residenza",
                },
              }),
              _c("q-select", {
                staticClass: "q-mb-sm",
                attrs: {
                  dense: "",
                  outlined: "",
                  options: _vm.options_cap_residenza,
                  label: "Cap",
                },
                model: {
                  value: _vm.dati_cliente_modificati.cap_residenza,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_cliente_modificati, "cap_residenza", $$v)
                  },
                  expression: "dati_cliente_modificati.cap_residenza",
                },
              }),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: {
                  dense: "",
                  outlined: "",
                  label: "Provincia",
                  disable: "",
                },
                model: {
                  value: _vm.dati_cliente_modificati.provincia_residenza,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dati_cliente_modificati,
                      "provincia_residenza",
                      $$v
                    )
                  },
                  expression: "dati_cliente_modificati.provincia_residenza",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-4 q-mr-sm" },
            [
              _c("div", { staticClass: "text-h5", attrs: { align: "left" } }, [
                _vm._v("Indirizzo recapiti"),
              ]),
              _c("br"),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: { dense: "", outlined: "", label: "Indirizzo" },
                model: {
                  value: _vm.dati_cliente_modificati.indirizzo_recapito,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dati_cliente_modificati,
                      "indirizzo_recapito",
                      $$v
                    )
                  },
                  expression: "dati_cliente_modificati.indirizzo_recapito",
                },
              }),
              _c("q-select", {
                staticClass: "q-mb-sm",
                attrs: {
                  name: "comune_recapito",
                  "emit-value": "",
                  dense: "",
                  "map-options": "",
                  "virtual-scroll-slice-size": "0",
                  "virtual-scroll-item-size": "0",
                  outlined: "",
                  "use-input": "",
                  "input-debounce": "0",
                  options: _vm.elenco_comuni_recapito,
                },
                on: {
                  filter: _vm.onCambiaComuneRecapito,
                  input: _vm.cambiaComuneRecapito,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "no-option",
                      fn: function () {
                        return [
                          _c(
                            "q-item",
                            [
                              _c(
                                "q-item-section",
                                { staticClass: "text-grey" },
                                [_vm._v(" Nessun risultato ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2139249595
                ),
                model: {
                  value: _vm.dati_cliente_modificati.comune_recapito,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dati_cliente_modificati,
                      "comune_recapito",
                      $$v
                    )
                  },
                  expression: "dati_cliente_modificati.comune_recapito",
                },
              }),
              _c("q-select", {
                staticClass: "q-mb-sm",
                attrs: {
                  dense: "",
                  outlined: "",
                  options: _vm.options_cap_recapito,
                  label: "Cap",
                },
                model: {
                  value: _vm.dati_cliente_modificati.cap_recapito,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_cliente_modificati, "cap_recapito", $$v)
                  },
                  expression: "dati_cliente_modificati.cap_recapito",
                },
              }),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: {
                  dense: "",
                  outlined: "",
                  label: "Provincia",
                  disable: "",
                },
                model: {
                  value: _vm.dati_cliente_modificati.provincia_recapito,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dati_cliente_modificati,
                      "provincia_recapito",
                      $$v
                    )
                  },
                  expression: "dati_cliente_modificati.provincia_recapito",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-4 q-mr-sm" },
            [
              _c("div", { staticClass: "text-h5", attrs: { align: "left" } }, [
                _vm._v("Altri dati"),
              ]),
              _c("br"),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: { dense: "", outlined: "", label: "Numero di telefono" },
                model: {
                  value: _vm.dati_cliente_modificati.telefono,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_cliente_modificati, "telefono", $$v)
                  },
                  expression: "dati_cliente_modificati.telefono",
                },
              }),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: {
                  dense: "",
                  outlined: "",
                  label: "Numero di cellulare",
                },
                model: {
                  value: _vm.dati_cliente_modificati.cellulare,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_cliente_modificati, "cellulare", $$v)
                  },
                  expression: "dati_cliente_modificati.cellulare",
                },
              }),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: { dense: "", outlined: "", label: "Indirizzo EMAIL" },
                model: {
                  value: _vm.dati_cliente_modificati.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_cliente_modificati, "email", $$v)
                  },
                  expression: "dati_cliente_modificati.email",
                },
              }),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: { dense: "", outlined: "", label: "Indirizzo PEC" },
                model: {
                  value: _vm.dati_cliente_modificati.pec,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_cliente_modificati, "pec", $$v)
                  },
                  expression: "dati_cliente_modificati.pec",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { attrs: { align: "center" } }, [
          _c("hr"),
          _c("div", { staticClass: "row justify-center" }, [
            _c(
              "div",
              { staticClass: "col-md-4", attrs: { align: "center" } },
              [
                _c("QQButton", {
                  attrs: {
                    label: "ANNULLA MODIFICHE",
                    color: "blue-grey",
                    icon: "undo",
                    size: "md",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onIndietroClicked()
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4", attrs: { align: "center" } },
              [
                _c("QQButton", {
                  attrs: {
                    label: "REGISTRA",
                    color: "blue-grey",
                    icon: "mdi-arrow-right-bold",
                    size: "md",
                    icon_align: "right",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onRegistraClicked()
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [_vm._v("Modifica dati Cliente")]),
      _c("hr"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }